@import "~bootstrap/scss/bootstrap";
@import "~font-awesome/scss/font-awesome";
@import "assets/css/font-lauis.css";
@import "assets/css/ionicons.min.css";
@import "assets/css/linea-font.css";
@import "assets/scss/style";

* {
  scroll-behavior: smooth;
}
