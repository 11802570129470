@font-face {
  font-family: "louis_george_cafebold_italic";
  src: url("../fonts/louis_george_cafe_bold_italic-webfont.woff2")
      format("woff2"),
    url("../fonts/louis_george_cafe_bold_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ethnoFont";
  src: url("../fonts/ETHNOCEN.TTF");
}

@font-face {
  font-family: "louis_george_cafebold";
  src: url("../fonts/louis_george_cafe_bold-webfont.woff2") format("woff2"),
    url("../fonts/louis_george_cafe_bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "louis_george_cafeitalic";
  src: url("../fonts/louis_george_cafe_italic-webfont.woff2") format("woff2"),
    url("../fonts/louis_george_cafe_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "louis_george_caf_lightitalic";
  src: url("../fonts/louis_george_cafe_light_italic-webfont.woff2")
      format("woff2"),
    url("../fonts/louis_george_cafe_light_italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "louis_george_cafe_lightRg";
  src: url("../fonts/louis_george_cafe_light-webfont.woff2") format("woff2"),
    url("../fonts/louis_george_cafe_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "louis_george_caferegular";
  src: url("../fonts/louis_george_cafe-webfont.woff2") format("woff2"),
    url("../fonts/louis_george_cafe-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
