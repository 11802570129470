/*====================
	Version: 1.0
======================*/

/**************************************************************
	
	STYLESHEET INDEXING
	|
	|
	|___ Default Styles
	|	|___ variables
	|	|___ mixins
	|	|___ reset
	|	|___ typography
	|	|___ animations
	|	|___ shortcode
	|
	|
	|___Elements Styles
	|	|___ Button
	|	|___ section-title
	|	|___ service
	|	|___ Counterup
	|	|___ team
	|	|___ wavify
	|	|___ wavify
	|	|___ blog-list
	|	|___ contact
	|	|___ about-us
	|	|___ call-to-action
	|	|___ slick-arrow
	|	|___ motivational-archive
	|	|___ social
	|	|___ shortnote
	|	|___ flexible-image-slide
	|	|___ carousel-slider
	|	|___ countdown
	|	|___ progress-charts
	|	|___ gradation
	|	|___ typed-text
	|	|___ pricing-plans
	|	|___ career
	|	|___ maintenance
	|	|___ player
	|	|___ 404
	|	
	|
	|
	|___Components Styles
	|	|___ video 
	|	|___ breadcrumb
	|	|___ instagram
	|	|___ popup
	|	|___ shopping-cart
	|	|___ mega-menu
	|	|___ toolbar
	|	|___ typography
	|	|___ list
	|	|___ pricing-box
	|	|___ icon-boxes
	|	|___ dividers
	|	|___ message-box
	|	|___ accordion
	|	|___ googlemap
	|	|___ rows-column
	|	|___ info-boxes
	|	|___ loader
	|	|___ demo-option
	|
	|
	|
	|___Portfolio Styles
	|	|___ portfolio-grid 
	|	|___ portfolio-grid-metro 
	|	|___ portfolio-caption
	|	|___ mesonry-button
	|	|___ paralax
	|	|___ has-center
	|	|___ type-hover
	|	|___ portfolio-details
	|
	|
	|
	|___Blog Grid Styles
	|	|___ blod-grid 
	|	|___ pagination 
	|	|___ blog-mesonry
	|	|___ blog-grid-no-flex
	|	|___ blog-grid-minimal
	|	|___ blog-details
	|	|___ blog-sidebar
	|
	|
	|
	|___Shop Sidebar Styles
	|	|___ shop-sidebar 
	|	|___ single-product 
	|	|___ cart
	|	|___ checkout
	|	|___ compare
	|	|___ my-account
	|	|___ vertical-slide
	|	|___ login
	|
	|
	|
	|
	|___Template Styles
	|	|___ header
	|	|___ sidebar-header
	|	|___ navigation
	|	|___ banner
	|	|___ feature
	|	|___ startup-service
	|	|___ freelancer
	|	|___ hamburger-menu
	|	|___ left-vertical-header
	|	|___ fullscreen-slider
	|	|___ sidebar-mobilemenu
	|	|___ header-broadsheets
	|	|___ onepage
	|	|___ foodie
	|	|___ portfolio-slide
	|	|___ photo-slide
	|	|___ vertical-slide
	|	|___ musician
	|	|___ vertical-slider-portfolio
	|	|___ shop-slide
	|	|___ product
	|	|___ revolution
	|	|___ presentation
	|	|___ carousel-slider
	|	 
	|	
	|	|___ Color Variation
	|	|
	|	|___ color-2
	|	|___ font-variation
	|	|___ preview
	|	|___ responsive
	|
	|___ END STYLESHEET INDEXING

***************************************************************/

/*===================================
    Default Styles
====================================*/
@import "default/variables";
@import "default/mixins";
@import "default/reset";
@import "default/typography";
@import "default/animations";
@import "default/shortcode";

/*====================================
	Elements Styles
=====================================*/
@import "elements/button";
@import "elements/section-title";
@import "elements/service";
@import "elements/counterup";
@import "elements/team";
@import "elements/wavify";
@import "elements/wavify";
@import "elements/brand";
@import "elements/blog-list";
@import "elements/contact";
@import "elements/about";
@import "elements/call-to-action";
@import "elements/slick-arrow";
@import "elements/motivational-archive";
@import "elements/social";
@import "elements/shortnote";
@import "elements/flexible-image-slide";
@import "elements/carousel-slider";
@import "elements/countdown";
@import "elements/progress-charts";
@import "elements/gallery";
@import "elements/gradation";
@import "elements/typed-text";
@import "elements/pricing-plans";
@import "elements/career";
@import "elements/maintenance";
@import "elements/testimonial";
@import "elements/404";
@import "elements/tabs";
@import "elements/player";

/*================================
	Components Style
=================================*/
@import "components/video";
@import "components/breadcrumb";
@import "components/instagram";
@import "components/popup";
@import "components/shopping-cart";
@import "components/mega-menu";
@import "components/toolbar";
@import "components/typography";
@import "components/list";
@import "components/pricing-box";
@import "components/icon-boxes";
@import "components/dividers";
@import "components/message-box";
@import "components/accordion";
//@import 'components/googlemap';
@import "components/rows-column";
@import "components/info-boxes";
//@import 'components/loader';
@import "components/hero-banner";
@import "components/slider";
//@import 'components/demo-option';

/*==================================
	Portfolio Styles
===================================*/
@import "portfolio/portfolio-grid";
@import "portfolio/portfolio-grid-metro";
@import "portfolio/portfolio-caption";
@import "portfolio/masonry-button";
@import "portfolio/paralax";
@import "portfolio/has-center";
@import "portfolio/type-hover";
@import "portfolio/portfolio-details";

/*=====================================
	Blog Grid Styles
======================================*/
@import "blog-grid/blod-grid";
@import "blog-grid/pagination";
@import "blog-grid/blog-mesonry";
@import "blog-grid/blog-grid-no-flex";
@import "blog-grid/blog-grid-minimal";
@import "blog-grid/blog-details";
@import "blog-grid/blog-sidebar";

/*====================================
	Shop Sidebar Styles
=====================================*/
@import "shop/shop-sidebar";
@import "shop/single-product";
@import "shop/cart";
@import "shop/checkout";
@import "shop/compare";
@import "shop/my-account";
@import "shop/vertical-slide";
@import "shop/login";

/*=================================
	Template Styles
==================================*/
@import "template/header";
@import "template/sidebar-header";
@import "template/navigation";
@import "template/banner";
@import "template/feature";
@import "template/startup-service";
@import "template/freelancer";
@import "template/hamber-menu";
@import "template/left-vertical-header";
@import "template/fullscreen-slider";
@import "template/sidebar-mobilemenu";
@import "template/header-broadsheets";
@import "template/onepage";
@import "template/foodie";
@import "template/portfolio-slide";
@import "template/photo-slide";
@import "template/vertical-slide";
@import "template/musician";
@import "template/vertical-slider-portfolio";
@import "template/shop-slide";
@import "template/product";
@import "template/revulution";
@import "template/presentation";
@import "template/footer";

/*================================
	Color Variation
=================================*/
@import "color/color-2";
@import "color/font-variation";
@import "color/preview";
@import "color/responsive";

.kawaColor {
  color: #aecc36 !important;
}

.kawaBG {
  background-color: #aecc36 !important;
}

.rowReverse {
  flex-direction: row-reverse !important;
}

.whiteColor {
  color: #fff !important;
}

.bold {
  font-weight: bold;
}

.darkBlueColor {
  color: #001029 !important;
}

.satzBackground {
  background-image: url("/satzGestaltung.jpg");
}

@font-face {
  font-family: "ethnoFont";
  src: url("/src/assets/fonts/ETHNOCEN.TTF");
}

.ethnoFont {
  font-family: "ethnoFont" !important;
}

.bitUnder {
  margin-top: 1rem;
}

.fullPage {
  height: 100vw;
}

.componentRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 50px;
  padding-right: 50px;
}

.componentIMG {
  width: 30%;
  height: 30%;
}

.paddingText {
  padding: 10px;
  padding-left: 20px;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  position: absolute;
  left: 0%;
  top: 90%;
}

.OGpaddingText {
  padding: 10px;
  padding-left: 20px;
  border-start-end-radius: 10px;
  border-end-end-radius: 10px;
  position: absolute;
  left: 0%;
  top: 90%;
}

@media (max-width: 1000px) {
  .componentRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .componentIMG {
    width: 100%;
    height: auto;
  }

  .paddingText {
    font-size: 30px;
    top: 60%;
  }

  .OGpaddingText {
    font-size: 30px;
    top: 70%;
  }
}

@media (min-width: 1000px) {
  .paddingText {
    font-size: 70px;
    top: 73%;
  }

  .OGpaddingText {
    font-size: 70px;
    top: 83%;
  }
}

.coolBG {
  background-color: rgba(0, 0, 0, 0.9);
}

.titleBG {
  background-color: rgb(0, 0, 0);
}

.red {
  color: red !important;
}

.smallText {
  font-size: 30px;
}

.unFixed {
  position: relative !important;
}

.zeroBottom {
  margin-bottom: 0px !important;
}
